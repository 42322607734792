import './App.css';
import {useCallback, useEffect, useRef, useState} from "react";
import Drawer from 'react-modern-drawer'
import 'react-modern-drawer/dist/index.css'
import ProviderDetail from "./ProviderDetail";
import {ProviderPreview} from "./ProviderPreview";

const iconMap = {
  "필라테스 Pilates": "pilates",
  "트레이닝 Training": "pt",
  "요가 Yoga": "yoga",
  "테니스 tennis": "ball",
  "골프 Golf": "golf",
  "복싱 Boxing": "boxing",
  "클라이밍 Climbing": "climbing",
  "헬스 PT": "pt",
  "크로스핏 Crossfit": "training",
};

function getCurrentLocation() {
  const defaultLocation = {
    latitude: 37.5046841,
    longitude: 127.0264266,
  };
  return new Promise((resolve, reject) => {
    if (!navigator.geolocation) {
      resolve(defaultLocation);
    } else {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          resolve({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
        },
        (error) => {
          resolve(defaultLocation);
        }
      );
    }
  });
}

function debounce(fn, delay) {
  let timeout;

  return function executedFunction(...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => fn(...args), delay);
  };
}

let markers = [];

function App() {
  const [providers, setProviders] = useState([]);
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [providerPreview, setProviderPreview] = useState(null);
  const [filteredProviders, setFilteredProviders] = useState([]);
  const mapRef = useRef(null);

  const debouncedBoundsChangedHandler = useCallback(debounce((bounds) => {
    if (!mapRef.current) return;

    const ne = bounds.getNE();
    const sw = bounds.getSW();
    const filtered = providers.filter(p => {
      return p.coordinates[0] >= sw.lat() && p.coordinates[0] <= ne.lat() && p.coordinates[1] >= sw.lng() && p.coordinates[1] <= ne.lng();
    });

    setFilteredProviders(filtered);
  }, 150), [providers]);

  async function renderMap() {
    const location = await getCurrentLocation();
    const n = window.naver;

    const mapOptions = {
      center: new n.maps.LatLng(location.latitude, location.longitude),
      mapTypeId: n.maps.MapTypeId.NORMAL,
      zoom: 13,
      minZoom: 7,
      zoomControl: true,
      zoomControlOptions: {
        style: n.maps.ZoomControlStyle.SMALL,
        position: n.maps.Position.RIGHT_BOTTOM,
      },
    };
    const map = new n.maps.Map('map', mapOptions);

    n.maps.Event.addListener(map, "bounds_changed", debouncedBoundsChangedHandler);
    mapRef.current = map;
    debouncedBoundsChangedHandler(map.getBounds());
  }

  useEffect(() => {
    const n = window.naver;

    filteredProviders.forEach(p => {
      let iconName = iconMap[(p.types || [''])[0]] || 'etc';
      const icon = {
        content: `<img id="${p.id}" class="provider-marker" src="https://www.jamfulapp.com/${iconName}.svg" alt="" style="margin: 0; padding: 0; border: 0 solid transparent; display: block; max-width: none; max-height: none; -webkit-user-select: none; position: absolute; width: 30px; height: 30px; left: 0; top: 0;">`,
        size: new n.maps.Size(30, 30),
      }

      const marker = new n.maps.Marker({
        position: new n.maps.LatLng(...p.coordinates),
        map: mapRef.current,
        icon,
      });
      n.maps.Event.addListener(marker, 'click', () => setProviderPreview(p));
      markers.push(marker);
      markers.push(marker);
    });

    return () => {
      markers.forEach(marker => {
        marker.setMap(null)
      });
      markers = [];
    }
  }, [providers, filteredProviders]);

  useEffect(() => {
    if (window.naver && providers.length > 0) {
      renderMap();
    }
  }, [providers.length]);

  useEffect(() => {
    fetch('https://api.jamfulapp.com/graphql', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        "operationName": "searchProviders",
        "variables": {
          "criteria": {
          }, "page": {"pageSize": 100000, "pageNumber": 1}
        },
        "query": "query searchProviders($criteria: ProviderSearchInput!, $page: Page!) {\n  searchProviders(criteria: $criteria, page: $page) {\n    rows {\n      description\n      name\n      photos\n      locationHint\n      types\n      coordinates\n      cancellationPolicy\n      types\n      operationHours\n    parkingGuide\n addressLink\n  __typename\n    }\n    pageInfo {\n      total\n      page\n      pages\n      __typename\n    }\n    __typename\n  }\n}"
      })
    })
      .then(response => response.json())
      .then(data => {
        const providers = data.data.searchProviders.rows.filter(p => p.coordinates);
        providers.forEach(p => {
          p.coordinates = p.coordinates.split(',').map(c => parseFloat(c));
        });
        setProviders(providers);
        console.log(providers[0])
      })
  }, []);

  const handleDrawerClose = () => {
    setSelectedProvider(null);
  }

  return (
    <div className="providers">
      <div id="map"></div>
      <div className="provider-list">
        {filteredProviders.map((p, i) => {
          const providerImage = p?.photos[0] || 'https://ik.imagekit.io/uncwgd4ot/jamful/placeholder-image.png';

          return (
            <div key={p.id} onClick={() => setSelectedProvider(p)}>
              <img src={providerImage} alt="provider"/>
              <div className="provider-info">
                <div className="name">{p.name}</div>
                <div className="location">{p.locationHint}</div>
                <div
                  className="activities">{(p.types || []).map(a => (a || '').replace(/[a-zA-Z]+/g, '').trim()).join(', ')}</div>
              </div>
            </div>
          )
        })}
      </div>
      <div className="provider-preview" style={{borderRadius: 8}}>
        <ProviderPreview provider={providerPreview}
                         onClick={() => setSelectedProvider(providerPreview)}
                         onClickOutSide={() => setProviderPreview(null)}/>
      </div>
      <Drawer
        open={!!selectedProvider}
        onClose={handleDrawerClose}
        direction='left'
        size={500}
      >
        <div style={{height: 400, overflowY: 'auto'}}>
          <ProviderDetail provider={selectedProvider}/>
        </div>
      </Drawer>
    </div>
  );
}

export default App;
