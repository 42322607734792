import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import dayjs from "dayjs";
import {useEffect} from "react";

const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
};

function convertOperatingHours(operationHours) {
  function setTime(time) {
    return dayjs().set('hour', parseInt(time.split(':')[0])).set('minute', parseInt(time.split(':')[1]));
  }

  const operationHourStr = [];
  operationHours.split('\n').forEach((line) => {
    const date = line.split(":")[0];
    const times = line.match(/\d{2}:\d{2}/g);

    if(date && times?.length === 2) {
      const start = setTime(times[0]);
      const startMinute = start.minute();
      const end = setTime(times[1]);
      const endMinute = end.minute();
      operationHourStr.push([
        date,
        start.format(startMinute === 0 ? 'A h시' : 'A h시 mm분'),
        end.format(endMinute === 0 ? 'A h시' : 'A h시 mm분'),
      ]);
    }
  });

  return operationHourStr;
}


export default function ProviderDetail({provider}) {

  useEffect(() => {
    if(!provider) {
      return;
    }
    const location = new window.naver.maps.LatLng(...provider.coordinates);
    const mapOptions = {
      center: location,
      mapTypeId: window.naver.maps.MapTypeId.NORMAL,
      zoom: 15,
      zoomControl: false,
    };
    const map = new window.naver.maps.Map(`map-${id}`, mapOptions);
    const marker = new window.naver.maps.Marker({
      position: location,
      map,
    });
  }, [provider]);

  if(!provider) {
    return;
  }


  const {
    id, description, operationHours = '', amenities = [],
    cancellationPolicy, address, addressLink,
    parkingGuide
  } = provider;

  return (
    <div>
        <Slider {...settings}>
          {provider.photos.map((image, index) => (
            <div key={index}>
              <img src={image} alt={image}
                   style={{width: '100%', height: 180, objectFit: 'cover'}}/>
            </div>
          ))}
        </Slider>
      <div style={{padding: 12}}>
        <div className="flex justify-between mb-2">
          <p className="text-lg font-bold mb-2">센터 소개</p>
        </div>
        {
          description.split('\n').map((line, index) => (
            <p key={index} className="text-sm mb-2">{line}</p>
          ))
        }
        <div className="mb-8">
          <p className="text-lg font-bold mb-2">운영 시간</p>
          {
            convertOperatingHours(operationHours).map((str, index) => {
              return (
                <div key={index} className="flex mb-1">
                  <p className="text-sm text-500" style={{width: 60}}>{str[0]}</p>
                  <p className="text-sm">{str[1]} - {str[2]}</p>
                </div>
              )
            })
          }
        </div>
        <div className="mb-8">
          <p className="text-lg font-bold mb-2">편의시설 및 서비스</p>
          <div className="flex flex-wrap">
            {
              amenities.map((line, index) => (
                <div key={index} className="text-sm amenities flex justify-center items-center mr-2 mb-2">{line}</div>
              ))
            }
          </div>
        </div>
        <div className="mb-8">
          <p className="text-lg font-bold mb-2">예약 및 예약 취소 정책</p>
          <p className="text-sm">{cancellationPolicy}</p>
        </div>
        <div className="mb-8">
          <p className="text-lg font-bold mb-2">주소 안내 </p>
          <div className="mb-2">
            <div id={`map-${id}`} className="rounded-lg" style={{height: 160}}/>
          </div>
          <p className="text-sm mb-1">{address}</p>
          <div className="flex text-sm">
            <a href={addressLink} target="_blank" className="underline"
               style={{marginLeft: 4}}>네이버지도 바로가기</a>
          </div>

        </div>
        <div className="mb-4">
          <p className="text-lg font-bold mb-2">주차 정보</p>
          <p className="text-sm">{parkingGuide || '-'}</p>
        </div>
      </div>
    </div>
  )
}
